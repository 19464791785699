// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
require("jquery")


window.$ = $;
window.jQuery = $;
window.Rails = Rails

require("slick-carousel");

import './bootstrap_custom.js'

import './homepage.js'
import './vote.js'

import 'slick-carousel/slick/slick.scss'
import 'slick-carousel/slick/slick-theme.scss'
import "../stylesheets/application.sass"
import "@fortawesome/fontawesome-free/css/all"
import "@nathanvda/cocoon"


Rails.start()
ActiveStorage.start()

