

$(document).ready(() => {
  $('.musicians.index .meet-judges #collapse-slick, .informations.faq .panel-heading').click(function () {
    sign = $(this).find('.sign')
    sign.toggleClass('plus')
    sign.toggleClass('sub')
  })

  $('.musicians.index .meet-judges .judges-list').slick({
    dots: true,
    speed: 500,
    arrows: false,
    touchThreshold: 4,
    infinite: false,
    rows: 1,
    slidesPerRow: 5,
    sildesToShow: 5,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          rows: 3,
          slidesPerRow: 2,
          sildesToShow: 6,
          slidesToScroll: 1
        }
      }
    ]
  })

  $('#collapseJudges.collapse').on('show.bs.collapse', function () {
    $('.musicians.index .meet-judges .judges-list').css('visibility', 'hidden')
  })

  $('#collapseJudges.collapse').on('shown.bs.collapse', function () {
    $('.musicians.index .meet-judges .judges-list').css('visibility', 'unset')
    $('.musicians.index .meet-judges .judges-list').css('display', 'block')
    $('.musicians.index .meet-judges .judges-list').get(0).slick.setPosition()
  })

  $('.pre-live-video .countdown-to-live').css('top', $('.pre-live-video video').height()/3 + 'px')

  $('.pre-live-margin, .live-margin').css('margin-top', $('.musicians.index > .wrapper').height() + 'px')
})
