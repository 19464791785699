(function() {
  var observer;

  $(document).on('DOMContentLoaded', function() {
    $('.fb-share-btn').click(function(e) {
      FB.ui({
        method: 'share',
        display: 'popup',
        href: e.target.dataset.href
      }, function(response) {});
    });

    setFingerprint = function() {
      return Fingerprint2.get(function(components) {
        var murmur, values;
        values = components.map(function(component) {
          return component.value;
        });
        murmur = Fingerprint2.x64hash128(values.join(''), 31);
        $('body').data('fingerprint', murmur);
        $.ajax({
          method: 'POST',
          url: '/registration',
          dataType: 'script',
          data: {
            fingerprint: murmur
          }
        });
      });
    };
    if (window.requestIdleCallback) {
      return requestIdleCallback(function() {
        setFingerprint();
      });
    } else {
      return setTimeout((function() {
        setFingerprint();
      }), 500);
    }
  });

  if (window.lozad) {
    observer = window.lozad();
    observer.observe();
  }

}).call(this);